import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BuilderLayoutComponent} from "@app/component/builder-layout/builder-layout.component";
import {AppAuthGuard} from "@app/app.authguard";
import { InternalErrorComponent } from "@app/_shared/component/error/internal-error/internal-error.component";
import { NotFoundComponent } from "@app/_shared/component/error/not-found/not-found.component";
import { UnauthorizedComponent } from "@app/_shared/component/error/unauthorized/unauthorized.component";


const routes: Routes = [
  {
    path: ':storeID',
    component: BuilderLayoutComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['shopyan_seller']
    },
  },
  {
    path: 'error',
    children: [
      {
        path: 'oops',
        component: InternalErrorComponent
      },
      {
        path: 'not-found',
        component: NotFoundComponent
      },
      {
        path: 'access-denied',
        component: UnauthorizedComponent
      }
    ]
  },
  {
    path: '**', redirectTo: 'error/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule {
}
