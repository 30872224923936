<div id="sections-palette">
  <ng-container *ngIf="!sections || sections.length == 0">
    <div class="no-section">{{ 'SECTIONS_PALETTE.SECTION_TO_ADD' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="sections && sections.length > 0">
    <ng-container *ngFor="let section of sections">
      <div (click)="selectSection(section)" class="section-item">
        <div class="section-img">
          <img [src]="'/assets/img/sections/'+section.type+'.svg'"/>
        </div>
        <span class="section-name">{{ 'SECTION.'+section.type | translate }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
