import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'button[yanLoadingBtn]',
    templateUrl: './yan-loading-button.component.html',
    styleUrls: ['./yan-loading-button.component.scss']
})
export class YanLoadingButtonComponent {

    @HostBinding('class.loading')
    @HostBinding('attr.aria-disabled')
    @Input()
    loading = false;

    constructor() {
    }
}
