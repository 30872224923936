import { GraphFieldValue } from "./graph-field-value.model";
import { GraphBlock } from "./graph-block.model";

export class GraphSection {
  code: string;
  sectionCode: string;
  hidden?: boolean;
  values?: GraphFieldValue[];
  fieldsGroups?: GraphBlock[];
  blocks?: GraphBlock[];
}
