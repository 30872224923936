import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {getStoreId} from "@app/helper/builder-helper";
import {KeycloakService} from "keycloak-angular";


@Injectable()
export class StoreHttpInterceptor implements HttpInterceptor {


  constructor(
    private keycloakService: KeycloakService,
    private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const storeId = getStoreId();
    if (storeId) {
      request = request.clone({
        setHeaders: {
          'storeID': storeId
        }
      });
    }
    return next.handle(request).pipe(catchError(error => this.handleError(error)));
  }


  private handleError(response: HttpErrorResponse) {

    let status = response.status;
    if (response.status === undefined) {
      status = 404;
    }

    switch (status) {
      case 400:
        return throwError(() => response);
      case 401:
        this.keycloakService.isLoggedIn().then(isLogged => {
          if (isLogged) {
            this.router.navigateByUrl('/error/access-denied').then();
          } else {
            this.keycloakService.clearToken();
            this.keycloakService.login({
              redirectUri: window.location.origin + this.router.url
            });
            return;
          }
        });
        return throwError(() => response);
      case 403:
        this.router.navigateByUrl('/error/access-denied').then();
        return EMPTY;
      case 404:
        this.router.navigateByUrl('/error/not-found').then();
        return EMPTY;
      default:
        this.router.navigateByUrl('/error/oops').then();
        return EMPTY;
    }

  }

}
