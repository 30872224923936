import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EventManager} from "@app/service/event-manager.service";
import {EventManagerEnum} from "@app/model/enum/event-manager.enum";
import {faArrowDown, faChevronDown, faCog, faEllipsisV, faPlus, faStream} from "@fortawesome/free-solid-svg-icons";
import {MatDialog} from "@angular/material/dialog";
import {PageFormComponent} from "@app/component/page-form/page-form.component";
import {Subscription} from "rxjs";
import {KeycloakService} from "keycloak-angular";
import {BuilderService} from "@app/service/builder.service";
import {YanToastrService} from "@app/service/yan-toastr.service";
import {extractErrorMessagesFromResponse, getStoreId} from "@app/helper/builder-helper";
import {environment} from "../../../environments/environment";
import {ConfirmationDialogComponent} from "@app/component/confirmation-dialog/confirmation-dialog.component";
import {SocketService} from "@app/service/socket.service";
import {UserService} from "@app/service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "@app/model/page.model";
import {GraphTemplate} from "@app/model/graph-template.model";
import {GraphPage} from "@app/model/graph-page.model";
import {Direction} from "@angular/cdk/bidi";

@Component({
  selector: 'app-builder-header',
  templateUrl: './builder-header.component.html',
  styleUrls: ['./builder-header.component.scss']
})
export class BuilderHeaderComponent implements OnInit, OnDestroy {

  // Icons
  faChevronDown = faChevronDown;
  faPlus = faPlus;
  faCog = faCog;

  // Display management
  displayMode = 1;

  // Inputs
  @Input()
  defaultPages: Page[];

  @Input()
  selectedPage: any;

  @Input()
  storeDomain: string;

  @Input()
  graphTemplate: GraphTemplate;

  // Outputs
  @Output()
  pageChanged = new EventEmitter();


  /**
   * Data
   */
  userName: string | undefined;
  avatar: string;
  adminURL: string;

  loading: boolean;

  private subscription: Subscription = new Subscription();

  constructor(public dialog: MatDialog,
              private eventManager: EventManager,
              private socketService: SocketService,
              private userService: UserService,
              private translate: TranslateService,
              private yanToastrService: YanToastrService,
              private builderService: BuilderService,
              private keycloakService: KeycloakService) {
  }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(isLogged => {
      if (isLogged) {
        this.keycloakService.loadUserProfile().then(profile => {
          this.userName = profile.username;
          this.avatar = this.userName ? this.userName.substring(0, 2) : 'ab';
        })
      }
    });

    this.keycloakService.getToken().then(token => {
      this.socketService.connect(token);
      this.socketService.rxStomp.watch({destination: "/user/queue/logout"})
        .subscribe((message) => {
          if (message.body == this.keycloakService.getKeycloakInstance().sessionId) {
            this.socketService.disconnect().then();
            this.keycloakService.login();
          }
        });
    });

    this.adminURL = `${environment.adminUiURL}store/${getStoreId()}`;
  }


  get currentPage(): Page {
    if (this.selectedPage.addedPage) {
      return this.selectedPage.addedPage;
    } else if (this.selectedPage.pageCode) {
      return this.defaultPages.filter(p => p.code === this.selectedPage.pageCode)[0];
    }
    // Landing page
    return this.selectedPage;
  }

  getPageStructure(code: string): Page | undefined {
    return this.defaultPages.find(p => p.code === code);
  }

  /**
   * Change display mode
   * @param dMode
   */
  changeDisplayMode(dMode: number) {
    this.displayMode = dMode;
    this.eventManager.broadcast({name: EventManagerEnum.DISPLAY_MODE, displayMode: dMode});
  }

  /**
   * Select page event
   * @param page
   */
  selectPage(page: any): void {
    this.pageChanged.emit(page);
  }


  /**
   * Open page form
   */
  openPageForm(edit?: boolean): void {

    let slugs = this.defaultPages.map(page => page.slug);
    this.graphTemplate.pages.filter(page => page.addedPage).forEach(item => {
      slugs.push(item.addedPage?.slug!);
    });
    this.graphTemplate.landingPages?.forEach(item => {
      slugs.push(item.slug);
    });

    if (edit) {
      slugs.splice(slugs.indexOf(this.selectedPage.addedPage?.slug!), 1);
    }

    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    const config = {
      width: '600px',
      autoFocus: false,
      data: {
        slugs: slugs,
        page: edit ? this.selectedPage.addedPage : null
      },
      direction: dir,
      panelClass: ['yan-dialog'],
      closeOnNavigation: true
    };
    const dialogRef = this.dialog.open(PageFormComponent, config);
    this.subscription.add(dialogRef.afterClosed().subscribe((result: GraphPage) => {
      if (result) {
        if (edit) {
          this.updateSelectedPage(result.addedPage!);
        } else {
          this.addPage(result);
        }
      }
    }));
  }

  /**
   * Add new page
   * @param page
   */
  addPage(page: GraphPage): void {
    this.graphTemplate.pages.push({...page});
    this.pageChanged.emit(page);
    this.builderService.refreshPages(this.defaultPages, this.graphTemplate.pages);
  }

  /**
   * Update added page
   * @param page
   */
  updateSelectedPage(page: Page): void {
    let p = this.graphTemplate.pages.find((gp: GraphPage) => gp.addedPage?.code === this.selectedPage.addedPage?.code);
    if (p) {
      p.addedPage!.slug = page.slug;
      p.addedPage!.name = page.name;
      this.pageChanged.emit(p);
      this.builderService.refreshPages(this.defaultPages, this.graphTemplate.pages);
    }
  }


  /**
   * Delete page confirmation
   */
  deletePage(): void {
    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    let config = {
      data: this.translate.instant('MESSAGES.CONFIRM_DELETE_PAGE'),
      direction: dir,
      panelClass: ['yan-dialog'],
      closeOnNavigation: true
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.deleteSelectedPage();
      }
    }));
  }

  /**
   * Delete selected page
   */
  private deleteSelectedPage(): void {
    if (this.selectedPage.addedPage) {
      this.graphTemplate.pages.splice(this.graphTemplate.pages.indexOf(this.selectedPage), 1);
      this.pageChanged.emit(this.graphTemplate.pages[0]);
      this.builderService.refreshPages(this.defaultPages, this.graphTemplate.pages);
    }
  }


  publish(): void {
    this.loading = true;
    const url = `${environment.storeApiUrl}/graphs/publish`;
    this.subscription.add(this.builderService.update(url, this.graphTemplate)
      .subscribe({
        next: (response: any) => {
          this.yanToastrService.success(this.translate.instant('HEADER.TEMPLATE_PUBLISHED'));
          this.loading = false;
        },
        error: (e: any) => {
          this.yanToastrService.error(extractErrorMessagesFromResponse(e));
          this.loading = false;
        }
      }));
  }

  logout() {
    this.userService.logout().subscribe();
    this.socketService.disconnect().then();
    this.keycloakService.logout(environment.adminUiURL + 'my-sites').then();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.socketService.disconnect().then();
    this.subscription.unsubscribe();
  }

}
