<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
  <div class="chip-options">
    <div class="chip-option"
         [class.checked]="(!multiple && data.value == option.code) || (multiple && data.value?.indexOf(option.code) >= 0)"
         (click)="toggleCheck(option.code)"
         *ngFor="let option of field.datasource!.options" >
      {{option.label}}
    </div>
  </div>
  <div class="yan-field-desc">{{field.designation}}</div>
</div>
