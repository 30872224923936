import { Component, OnInit } from '@angular/core';
import { AbstractFieldComponent } from "@app/component/abstrat-field.component";

@Component({
  selector: 'app-slide-toggle-field',
  templateUrl: './slide-toggle-field.component.html',
})
export class SlideToggleFieldComponent extends AbstractFieldComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
