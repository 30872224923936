import { Component, OnInit } from '@angular/core';
import { AbstractFieldComponent } from "@app/component/abstrat-field.component";

@Component({
  selector: 'app-check-field',
  templateUrl: './check-field.component.html',
  styleUrls: ['./check-field.component.scss']
})
export class CheckFieldComponent extends AbstractFieldComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!(this.data.value instanceof Array)) {
      this.data.value = [];
    }
  }

  toggleCheck(code: string): void {
    if (this.data.value.indexOf(code) < 0) {
      this.data.value.push(code);
    } else {
      this.data.value.splice(this.data.value.indexOf(code), 1);
    }

  }

}
