<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
<div class="check-options">
  <div class="check-option" *ngFor="let option of field.datasource!.options" >
    <mat-checkbox [checked]="data.value?.indexOf(option.code) >= 0"
                  (change)="toggleCheck(option.code)"
                  [value]="option.code">{{option.label}} </mat-checkbox>
  </div>
</div>
  <div class="yan-field-desc">{{field.designation}}</div>
</div>
