<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
  <div class="uploader" *ngIf="!data.value"
       (click)="openFileUploader()"
       ng2FileDrop
       [uploader]="uploader"
       (fileOver)="fileOverUploader($event)"
       [class.file-over-class]="uploaderHasDropZoneOver">
    <div class="add-img">{{ 'COMMON.IMAGE_UPLOADER.UPLOAD_IMAGE' | translate }}</div>
    <div class="drop-img">{{ 'COMMON.IMAGE_UPLOADER.DROP_IMAGE' | translate }}</div>
    <input type="file" ng2FileSelect [uploader]="uploader" [hidden]="true"
           #fileUploaderInput/>
  </div>
  <div class="uploaded-image" *ngIf="data.value">
    <div class="delete" (click)="removeMedia()">
      <fa-icon [icon]="faTrashAlt"></fa-icon>
    </div>
    <img [src]="urMedia(data.value)"/>
  </div>
  <div class="yan-field-desc">{{field.designation}}</div>
</div>


