import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {genUniqueId} from "@app/helper/builder-helper";
import {GraphPage} from "@app/model/graph-page.model";
import {Page} from "@app/model/page.model";

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrls: ['./page-form.component.scss']
})
export class PageFormComponent implements OnInit {

  formGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<PageFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.data?.page?.name, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      slug: [this.data?.page?.slug, [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-z0-9-]+$'), (control: AbstractControl) => {
        return !this.data?.slugs || this.data.slugs.indexOf(control.value) === -1 ? null : {'duplicated': true};
      }]]
    });
  }

  /**
   * Generate a slug from the page title
   */
  generatePageSlug(): void {
    const slugControl = this.formGroup.get('slug');
    if (!slugControl?.value) {
      slugControl?.setValue(this.formGroup.get('name')?.value.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, ''));
    }
  }


  /**
   * Validate page form
   */
  validatePage(): void {
    if (this.formGroup.valid) {
      const graphPage = new GraphPage();
      graphPage.addedPage = new Page();
      graphPage.addedPage.code = genUniqueId();
      Object.assign(graphPage.addedPage, this.formGroup.value);
      graphPage.sections = [];
      this.dialogRef.close(graphPage);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }


}
