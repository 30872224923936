import {Component, Input, OnInit} from '@angular/core';
import {BuilderFieldType} from "@app/model/enum/builder-field-type.enum";
import {BuilderField} from "@app/model/builder-field.model";
import {GraphFieldValue} from "@app/model/graph-field-value.model";

@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.scss']
})
export class CommonFormComponent implements OnInit {

  // Enum
  builderFieldTypeEnum = BuilderFieldType;

  @Input()
  fields: BuilderField[];

  @Input()
  data: GraphFieldValue[];


  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * Get field data if exists, else create new instance with the same field code
   * @param fieldCode
   */
  getFieldData(field: BuilderField): GraphFieldValue {

    let fieldData = this.data.find(item => item.fieldCode === field.code);
    if (!fieldData) {
      fieldData = new GraphFieldValue();
      fieldData.fieldCode = field.code;
      fieldData.value = field.defaultValue;
      this.data.push(fieldData);
    }
    return fieldData;
  }

}
