import { Component, OnInit } from '@angular/core';
import { AbstractFieldComponent } from "@app/component/abstrat-field.component";

@Component({
  selector: 'app-color-field',
  templateUrl: './color-field.component.html'
})
export class ColorFieldComponent extends AbstractFieldComponent implements OnInit {

  color: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.color = this.data.value;
  }

  selectColor():void{
    this.data.value = this.color;
  }

}
