<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
  <input [(colorPicker)]="color" [cpEyeDropper]="true"
         (colorPickerClose)="selectColor()"
         [style.background]="data.value" class="yan-input yan-color" readonly/>
  <div class="yan-field-desc">{{field.designation}}</div>
</div>
