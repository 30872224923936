import {Component, Input} from "@angular/core";
import {BuilderField} from "@app/model/builder-field.model";
import {GraphFieldValue} from "@app/model/graph-field-value.model";

@Component({
  template: ''
})
export abstract class AbstractFieldComponent {

  @Input()
  field: BuilderField;

  @Input()
  data: GraphFieldValue;

}
