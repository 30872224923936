import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {YanToastrService} from "@app/service/yan-toastr.service";
import {YanLoaderService} from "@app/service/yan-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {BuilderService} from "@app/service/builder.service";
import {MediaSize, MediaType} from "@app/model/enum/media-type.enum";
import {environment} from "../../../../../environments/environment";
import {getStoreId} from "@app/helper/builder-helper";

@Component({
  selector: 'app-editor-modal',
  templateUrl: './editor-modal.component.html',
  styleUrls: ['./editor-modal.component.scss']
})
export class EditorModalComponent implements OnInit {

  mimeTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/webp'];

  editorConfig = {
    blotFormatter: {}
  };

  constructor(public dialogRef: MatDialogRef<EditorModalComponent>,
              private translate: TranslateService,
              private yanToastrService: YanToastrService,
              private yanLoader: YanLoaderService,
              private builderService: BuilderService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  onEditorCreated(quill: any) {
    quill.getModule('toolbar').addHandler('image', () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();

      input.onchange = () => {
        this.yanLoader.startLoader('main-spinner');
        if(!input?.files || input?.files?.length === 0) {
          this.yanLoader.stopLoader('main-spinner');
          return;
        }
        const file = input?.files[0];
        if (this.mimeTypes.includes(file.type)) { // File types supported for image
          if (file.size < 5 * 1048576) { // Customize file size as per requirement
            return this.builderService.uploadImage(file).toPromise()
              .then(mediaId => {
                const range = quill.getSelection(true);
                quill.insertEmbed(range.index, 'image', environment.mediaURL + '/' + getStoreId() + '/' + MediaType.STORE.toLowerCase()
                  + '/' + MediaSize.original + '/' + mediaId + '.webp');
                quill.setContents(quill.getContents(), "user");
                this.yanLoader.stopLoader('main-spinner');
              })
              .catch(error => {
                // Handle error control
                console.error('Error:', error);
                this.yanToastrService.error(this.translate.instant('COMMON.EDITOR_FIELD.MEDIA.ERROR_IMPORT'));
                this.yanLoader.stopLoader('main-spinner');
                return;
              });
          } else {
            // Handle Image size large logic
            this.yanToastrService.error(this.translate.instant('COMMON.EDITOR_FIELD.ERROR_MAX_SIZE'));
            this.yanLoader.stopLoader('main-spinner');
            return;
          }
        } else {
          // Handle Unsupported type logic
          this.yanToastrService.error(this.translate.instant('COMMON.EDITOR_FIELD.ERROR_MIME_TYPE'));
          this.yanLoader.stopLoader('main-spinner');
          return ;
        }
      };
    });
  }

  validate(): void{
    this.dialogRef.close(this.data);
  }

  closeModal(): void{
    this.dialogRef.close();
  }
}
