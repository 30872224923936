<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>

  <ng-container *ngIf="field.datasource && field.datasource.options && field.datasource.options.length > 0 else dynamicSelect">
    <ng-select
      [appendTo]="'body'"
      [items]="field.datasource.options"
      bindLabel="label"
      bindValue="code"
      [multiple]="multiple"
      [(ngModel)]="data.value">
    </ng-select>
  </ng-container>

  <ng-template #dynamicSelect>
    <ng-select
      [appendTo]="'body'"
      [items]="options"
      bindLabel="name"
      bindValue="id"
      [multiple]="multiple"
      [(ngModel)]="data.value"
      (search)="filterData($event.term)" [loading]="loader">
    </ng-select>
  </ng-template>

  <div class="yan-field-desc">{{field.designation}}</div>
</div>
