import { Injectable } from "@angular/core";
import { Observable, Observer, Subscription } from "rxjs";
import { filter, share } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EventManager {
  observable$: Observable<any>;
  observer: Observer<any>;

  constructor() {
    this.observable$ = new Observable<any>(observer => this.observer = observer).pipe(share());
  }

  broadcast(event: any) {
    if (this.observer) {
      this.observer.next(event);
    }
  }

  subscribe(eventName: any, callback: any) {
    const subscriber: Subscription = this.observable$
      .pipe(
        filter(event => {
          return event.name === eventName;
        })
      ).subscribe(callback);
    return subscriber;
  }

}
