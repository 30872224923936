import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class UserService {

    constructor(private http: HttpClient) {
    }

    /**
     * Logout from session
     */
    public logout(): Observable<any> {
        let url = `${environment.storeApiUrl}/user/logout`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post(url, httpOptions);
    }

    public getLang(): Observable<any> {
      let url = `${environment.storeApiUrl}/user/lang`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ignoreError': 'true'
        }),
        responseType: 'text' as 'json'
      };

      return this.http.get(url, httpOptions);
    }
}

