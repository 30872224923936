<div id="common-form">

  <ng-container *ngFor="let field of fields">
    <ng-container [ngSwitch]="field.type">

      <ng-container *ngSwitchCase="builderFieldTypeEnum.INPUT">
        <app-input-field [data]="getFieldData(field)" [field]="field"></app-input-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.LINK">
        <app-link-field [data]="getFieldData(field)" [field]="field"></app-link-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.TEXTAREA">
        <app-text-field [data]="getFieldData(field)" [field]="field"></app-text-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.COLOR">
        <app-color-field [data]="getFieldData(field)" [field]="field"></app-color-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.SLIDE_TOGGLE">
        <app-slide-toggle-field [data]="getFieldData(field)" [field]="field"></app-slide-toggle-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.SLIDER">
        <app-slider-field [data]="getFieldData(field)" [field]="field"></app-slider-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.CHECKBOX">
        <app-check-field [data]="getFieldData(field)" [field]="field"></app-check-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.RADIO">
        <app-radio-field [data]="getFieldData(field)" [field]="field"></app-radio-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.CHIPS">
        <app-chip-field [data]="getFieldData(field)" [field]="field"></app-chip-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.MULTI_CHIPS">
        <app-chip-field [data]="getFieldData(field)" [field]="field" [multiple]="true"></app-chip-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.SELECT">
        <app-select-field [data]="getFieldData(field)" [field]="field"></app-select-field>
      </ng-container>

      <ng-container *ngSwitchCase="field.type == builderFieldTypeEnum.MULTI_SELECT">
        <app-select-field [data]="getFieldData(field)" [field]="field" [multiple]="true"></app-select-field>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.IMAGE">
        <app-image-uploader [data]="getFieldData(field)" [field]="field"></app-image-uploader>
      </ng-container>

      <ng-container *ngSwitchCase="builderFieldTypeEnum.EDITOR">
        <app-editor-field [data]="getFieldData(field)" [field]="field"></app-editor-field>
      </ng-container>

    </ng-container>
  </ng-container>

</div>
