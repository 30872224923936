import { Component, OnInit } from '@angular/core';
import { AbstractFieldComponent } from "@app/component/abstrat-field.component";

@Component({
  selector: 'app-radio-field',
  templateUrl: './radio-field.component.html',
  styleUrls: ['./radio-field.component.scss']
})
export class RadioFieldComponent extends AbstractFieldComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
