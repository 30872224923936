import {Component, OnInit} from '@angular/core';
import {YanLoaderService} from "@app/service/yan-loader.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class InternalErrorComponent implements OnInit {

  constructor(private translate: TranslateService,
              private yanLoaderService: YanLoaderService) {
  }

  ngOnInit(): void {
    this.yanLoaderService.stopProgressBar();
  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }

}
