import {Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {EventManager} from "@app/service/event-manager.service";
import {EventManagerEnum} from "@app/model/enum/event-manager.enum";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {getStoreId} from "@app/helper/builder-helper";
import {YanLoaderService} from "@app/service/yan-loader.service";
import {GraphTemplate} from "@app/model/graph-template.model";
import {GraphPage} from "@app/model/graph-page.model";
import {GraphLandingPage} from "@app/model/graph-landing-page.model";

@Component({
  selector: 'app-builder-display',
  templateUrl: './builder-display.component.html',
  styleUrls: ['./builder-display.component.scss']
})
export class BuilderDisplayComponent implements OnInit, OnDestroy, DoCheck {

  @ViewChild('iframe') iframe: ElementRef;

  subscribers: Subscription = new Subscription();

  @Input()
  graph: GraphTemplate;

  @Input()
  currentPage: GraphPage | GraphLandingPage | undefined;

  @Input()
  currency: string;

  @Input()
  defaultLanguage: string;
  @Input()
  languages: string[];

  displayMode: number = 1;

  urlSafe: SafeResourceUrl;

  graphBeforeCheck: string;

  dragMode = false;

  lastUpdate = new Date().getTime();

  constructor(public sanitizer: DomSanitizer, private yanLoader: YanLoaderService, private eventManager: EventManager) {
  }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.storeURL);

    this.subscribers.add(this.eventManager.subscribe(EventManagerEnum.DISPLAY_MODE, (response: any) => {
      this.displayMode = response.displayMode;
    }));

    this.subscribers.add(this.eventManager.subscribe(EventManagerEnum.DRAG_DISPLAY_MODE, (response: any) => {
      if (this.displayMode == 1) {
        this.dragMode = true;
      }
    }));

    this.subscribers.add(this.eventManager.subscribe(EventManagerEnum.DROP_DISPLAY_MODE, (response: any) => {
      this.dragMode = false;
    }));

    this.subscribers.add(this.eventManager.subscribe(EventManagerEnum.SELECT_SECTION, (response: any) => {
      this.sendSectionToSelect(response.section);
    }));

    this.subscribers.add(this.eventManager.subscribe(EventManagerEnum.CHANGE_PAGE, (response: any) => {
      this.sendDataToPreview(response.page);
    }));

  }

  ngDoCheck() {
    const graphSTR = JSON.stringify(this.graph);
    if (this.graphBeforeCheck !== graphSTR && this.currentPage && new Date().getTime() - this.lastUpdate >= 500) {
      this.sendDataToPreview(this.currentPage);
      this.graphBeforeCheck = graphSTR;
      this.lastUpdate = new Date().getTime();
    }
  }


  sendDataToPreview(page: any): void {

    this.yanLoader.startLoader('display-spinner');
    // TODO https://stackoverflow.com/questions/58241780/localhost-postmessage-target-origin-provided-does-not-match-the-recipient-wind
    if (this.iframe) {
      const event = {
        type: 'build',
        page: page,
        settings: this.graph.settings,
        storeId: getStoreId(),
        currency: this.currency,
        landingPageSlug: page.landingPageCode ? page.slug : undefined,
        defaultLanguage: this.defaultLanguage,
        languages: this.languages
      }
      // TODO change origin '*'
      this.iframe.nativeElement.contentWindow.postMessage(event, "*");
    }
    this.yanLoader.stopLoader('display-spinner');
  }


  sendSectionToSelect(sectionCode: string): void {
    // TODO https://stackoverflow.com/questions/58241780/localhost-postmessage-target-origin-provided-does-not-match-the-recipient-wind
    if (this.iframe) {
      const event = {
        type: 'select',
        section: sectionCode
      }
      // TODO change origin '*'
      this.iframe.nativeElement.contentWindow.postMessage(event, "*");
    }
  }

  ngOnDestroy(): void {
    this.subscribers.unsubscribe();
  }
}
