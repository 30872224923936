import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@app/service/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['/app.component.scss']
})
export class AppComponent {
    title = 'shopyan-builder';
    loading = true;

    constructor(private translate: TranslateService,
                private userService: UserService) {
      this.userService.getLang().subscribe(lang => {
        if (lang && lang !== '') {
          this.translate.use(lang);
        }
        this.loading = false;
      })
    }

  get direction():any{
    return this.translate.currentLang === 'ar' ? 'rtl':'ltr';
  }

}
