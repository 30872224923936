export const environment = {
  production: true,
  adminUiURL: 'https://seller.shopyan.com/',
  storeURL: 'https://store.shopyan.com',
  storeApiUrl: 'https://api.svc.shopyan.com/store/admin/api',
  productApiUrl: 'https://api.svc.shopyan.com/products/admin/api',
  mediaURL: 'https://api.svc.shopyan.com/products/media',
  socketUrl: 'wss://api.svc.shopyan.com/ws/websocket',
  keycloak: {
    // Url of the Identity Provider
    url: 'https://auth.svc.shopyan.com/auth',
    realm: 'shopyan-auth',
    clientId: 'shopyan-client',
  }
};
