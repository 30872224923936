import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractFieldComponent} from "@app/component/abstrat-field.component";
import {BuilderService} from "@app/service/builder.service";
import {Pageable} from "@app/model/pageable.model";
import {Subscription} from "rxjs";
import {MenuTargetType} from "@app/model/enum/menu-target-type.enum";
import {Link} from "@app/model/link.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-link-field',
  templateUrl: './link-field.component.html',
  styleUrls: ['./link-field.component.scss']
})
export class LinkFieldComponent extends AbstractFieldComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  options: any[] = [];
  loader: boolean;

  targetTypes = Object.values(MenuTargetType);
  menuTargetType = MenuTargetType;

  constructor(private builderService: BuilderService, private translate: TranslateService) {

    super();
  }

  ngOnInit(): void {
    if (!this.data.value || !(this.data.value instanceof Link)) {
      this.data.value = new Link();
    }
    this.filterData();
  }

  changeType(): void {
    delete this.data.value.value;
    this.filterData();
  }

  filterData(filter?: string): void {

    if (this.data.value?.target === MenuTargetType.COLLECTION) {
      this.loader = true;
      const sub = this.builderService.filterCollections(new Pageable(), filter).subscribe(response => {
        this.options = response.data;
        this.loader = false;
      });
      this.subscription.add(sub);

    } else if (this.data?.value.target === MenuTargetType.PRODUCT) {
      this.loader = true;
      const sub = this.builderService.filterProducts(new Pageable(), filter).subscribe(response => {
        this.options = response;
        if(this.options){
          this.options.forEach(option => {
            option.id = option.slug;
          });
        }
        this.loader = false;
      });
      this.subscription.add(sub);

    } else if (this.data?.value.target === MenuTargetType.PAGE) {
      this.options = [];
      this.builderService.pages.forEach(page => {
        this.options.push({
          name: this.translate.instant("PAGE." + page.code),
          id: page.slug
        });
      })
    }

  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
