<form *ngIf="formGroup" [formGroup]="formGroup">
  <div class="yan-modal" id="page-form">
    <div (click)="closeModal()" class="close-modal"></div>
    <div class="yan-modal-title">{{ 'PAGE_FORM.PAGE_FORM' | translate }}</div>
    <div class="yan-modal-content">

      <div class="yan-field">
        <div class="yan-field-label">
          <label class="yan-label">
            <span>{{ 'PAGE_FORM.PAGE_NAME' | translate }}</span>
            <span class="mandatory">*</span>
          </label>
        </div>
        <input (change)="generatePageSlug()" class="yan-input" InputValidator
               formControlName="name" placeholder="{{ 'PAGE_FORM.PAGE_NAME_PLACEHOLDER' | translate }}"
               type="text"/>
        <app-field-error controlName="name" errorKey="required">
          {{ 'PAGE_FORM.REQUIRED_FIELD' | translate }}
        </app-field-error>
        <app-field-error controlName="name" errorKey="minlength">
          {{ 'PAGE_FORM.MIN_LENGTH_ERROR' | translate }}
        </app-field-error>
        <app-field-error controlName="name" errorKey="maxlength">
          {{ 'PAGE_FORM.PAGE_NAME_MAX_LENGTH_ERROR' | translate }}
        </app-field-error>
      </div>

      <div class="yan-field">
        <div class="yan-field-label">
          <label class="yan-label">
            <span>{{ 'PAGE_FORM.URL_SLUG' | translate }}</span>
            <span class="mandatory">*</span>
          </label>
        </div>
        <input class="yan-input" formControlName="slug"
               placeholder="/url-slug" type="text" InputValidator/>
        <app-field-error controlName="slug" errorKey="required">
          {{ 'PAGE_FORM.REQUIRED_FIELD' | translate }}
        </app-field-error>
        <app-field-error controlName="slug" errorKey="pattern">
          {{ 'PAGE_FORM.INVALID_FIELD' | translate }}
        </app-field-error>
        <app-field-error controlName="slug" errorKey="maxlength">
          {{ 'PAGE_FORM.URL_SLUG_MAX_LENGTH_ERROR' | translate }}
        </app-field-error>
        <app-field-error controlName="slug" errorKey="duplicated">
          {{ 'PAGE_FORM.SLUG_ALREADY_USED' | translate }}
        </app-field-error>
      </div>

    </div>
    <div class="yan-modal-btns">
      <button (click)="validatePage()" class="yan-btn">{{ 'BUTTON.SAVE' | translate }}</button>
      <button class="yan-btn white-btn" (click)="closeModal()">{{ 'BUTTON.CANCEL' | translate }}</button>
    </div>
  </div>
</form>
