import {Component, OnInit} from '@angular/core';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {environment} from "../../../../environments/environment";
import {FileUploaderComponent} from "@app/_shared/component/file-uploader.component";
import {YanLoaderService} from "@app/service/yan-loader.service";
import {YanToastrService} from "@app/service/yan-toastr.service";
import {KeycloakService} from "keycloak-angular";
import {getStoreId} from "@app/helper/builder-helper";
import {MediaSize, MediaType} from "@app/model/enum/media-type.enum";

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent extends FileUploaderComponent implements OnInit {

  faTrashAlt = faTrashAlt;

  constructor(loaderService: YanLoaderService,
              toastrService: YanToastrService,
              keycloakService: KeycloakService) {
    super(loaderService, toastrService, keycloakService);
  }

  ngOnInit(): void {
    // File upload attributes
    this.maxSize = 5 * 1048576;
    this.mimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
    this.maxFiles = 10;
    this.uploadURL = `${environment.productApiUrl}/media/upload?type=` + MediaType.STORE;
    this.initUploader();
  }

  /**
   * Push new media file
   * @param fileCode
   */
  pushNewFile(fileCode: string): void {
    this.data.value = fileCode;
  }

  /**
   * Total uploaded files
   */
  get totalUploadedFiles(): number {
    return 0;
  }

  removeMedia(): void {
    delete this.data.value;
  }

  urMedia(media: string): string {
    return environment.mediaURL + '/' + getStoreId() + '/' + MediaType.STORE.toLowerCase() + '/' + MediaSize.medium + '/' + media + '.webp';
  }

}
