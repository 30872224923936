<div *ngIf="structure" id="builder-layout">
  <ngx-spinner name="main-spinner" type="ball-clip-rotate"></ngx-spinner>
  <div class="bl-header">
    <app-builder-header
      *ngIf="selectedPageData"
      [storeDomain]="storeDefaultDomain"
      [defaultPages]="structure.pages"
      [graphTemplate]="templateData"
      [selectedPage]="selectedPageData"
      (pageChanged)="changePage($event)"></app-builder-header>
  </div>
  <div class="bl-body" [class.full-display]="displayMode == 0">
    <div class="blb-menu">
      <div class="blb-menu-content">
        <app-builder-main-menu
          *ngIf="selectedPageData"
          [graphPage]="selectedPageData"
          [sections]="possibleSections"
          [defaultSections]="defaultSections"
          [settingBlocks]="structure.settings"
          [template]="templateData"
        ></app-builder-main-menu>
      </div>
    </div>

    <div class="blb-display">
      <div class="blb-display-content">
        <app-builder-display [graph]="templateData"
                             [defaultLanguage]="defaultLanguage"
                             [languages]="languages"
                             [currentPage]="selectedPageData" [currency]="currency"></app-builder-display>
      </div>
    </div>
  </div>
</div>
