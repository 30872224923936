import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractFieldComponent} from "@app/component/abstrat-field.component";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {EditorModalComponent} from "@app/component/common-form/editor-field/editor-modal/editor-modal.component";
import {GraphPage} from "@app/model/graph-page.model";
import {Direction} from "@angular/cdk/bidi";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-editor-field',
  templateUrl: './editor-field.component.html',
  styleUrls: ['./editor-field.component.scss']
})
export class EditorFieldComponent extends AbstractFieldComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  constructor(public dialog: MatDialog, private translate: TranslateService) {
    super();

  }

  ngOnInit(): void {
  }

  openEditor(): void {
    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    const config = {
      panelClass: 'full-screen-modal',
      autoFocus: false,
      data: this.data.value,
      direction: dir,
      closeOnNavigation: true
    };

    const dialogRef = this.dialog.open(EditorModalComponent, config);
    this.subscription.add(dialogRef.afterClosed().subscribe((result: GraphPage) => {
      if (result) {
        this.data.value = result;
      }
    }));
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
