import {Component, OnInit} from '@angular/core';
import {YanLoaderService} from "@app/service/yan-loader.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private translate: TranslateService,
              private yanLoaderService: YanLoaderService) {
  }

  ngOnInit(): void {
    this.yanLoaderService.stopProgressBar();
  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }
}
