<div id="builder-menu">

  <div class="bm-content">
    <div id="page-sections"
         (cdkDropListDropped)="drop($event)"
         class="animated tdFadeInUp"
         cdkDropList
         *ngIf="!displaySetting">

      <ng-container *ngFor="let graphSectionItem of graphPage.sections">
        <ng-container *ngFor="let section of sections">

          <div *ngIf="section.code == graphSectionItem.sectionCode" class="section-item">
            <div class="section-content" cdkDrag
                 (cdkDragStarted)="onDragStarted(graphSectionItem.code)"
                 [class.current]="selectedSectionGraphCode == graphSectionItem.code">
              <div class="section-main" (click)="displaySectionForm(graphSectionItem, section)">
                <div class="section-ic">
                  <img [src]="'/assets/img/sections/'+section.type+'.svg'"
                       onerror="this.src='/assets/img/sections/section.svg'"/>
                </div>
                <div class="section-def">
                  <div class="section-lbl">{{ 'SECTION.'+section.type | translate }}</div>
                </div>
              </div>
              <div class="section-actions" [class.keep]="graphSectionItem.hidden">
                <ng-container *ngIf="!section.sticky || defaultSections.indexOf(section.code) < 0">
                  <div class="delete"
                       (click)="deleteSection(graphSectionItem)">
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </div>
                  <div class="show" (click)="graphSectionItem.hidden = !graphSectionItem.hidden"
                       *ngIf="!graphSectionItem.hidden">
                    <fa-icon [icon]="faEye"></fa-icon>
                  </div>
                  <div class="show" (click)="graphSectionItem.hidden = !graphSectionItem.hidden"
                       *ngIf="graphSectionItem.hidden">
                    <fa-icon [icon]="faEyeSlash"></fa-icon>
                  </div>
                </ng-container>
                <div class="section-select"
                     *ngIf="(graphSectionItem.fieldsGroups && graphSectionItem.fieldsGroups.length > 0)
                      || (graphSectionItem.blocks && graphSectionItem.blocks.length > 0)"
                     (click)="toggleSectionGraph(graphSectionItem.code)">
                  <fa-icon [icon]="faChevronRight"></fa-icon>
                </div>
              </div>
            </div>

            <ng-container class="section-details animated tdFadeInDown"
                          *ngIf="selectedSectionGraphCode == graphSectionItem.code">

              <div class="block-items">

                <!-- FIELDS GROUP -->
                <ng-container *ngIf="graphSectionItem.fieldsGroups && graphSectionItem.fieldsGroups.length > 0">
                  <div class="block-item"
                       *ngFor="let blockGraph of graphSectionItem.fieldsGroups">
                    <ng-container *ngFor="let block of section.fieldsGroups">
                      <ng-container *ngIf="blockGraph.blockCode == block.code">
                        <div class="brdr-l"></div>
                        <div class="brdr-rd"></div>
                        <div class="bi-content">
                          <div class="block-actions" [class.keep]="blockGraph.hidden">
                            <ng-container *ngIf="block.canBeHidden">
                              <div class="show" (click)="blockGraph.hidden = !blockGraph.hidden"
                                   *ngIf="!blockGraph.hidden">
                                <fa-icon [icon]="faEye"></fa-icon>
                              </div>
                              <div class="show" (click)="blockGraph.hidden = !blockGraph.hidden"
                                   *ngIf="blockGraph.hidden">
                                <fa-icon [icon]="faEyeSlash"></fa-icon>
                              </div>
                            </ng-container>
                          </div>
                          <span (click)="displayBlockForm(blockGraph, block)">{{block.label}}</span>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>

                <!-- BLOCKS  -->
                <div cdkDropList (cdkDropListDropped)="dropBlock($event)"
                     *ngIf="graphSectionItem.blocks && graphSectionItem.blocks.length > 0">
                  <div class="block-item" cdkDrag [cdkDragDisabled]="!section.canOrderBlocks"
                       *ngFor="let blockGraph of graphSectionItem.blocks">
                    <ng-container *ngFor="let block of section.possibleBlocks">
                      <ng-container *ngIf="blockGraph.blockCode == block.code">
                        <div class="brdr-l"></div>
                        <div class="brdr-rd"></div>
                        <div class="bi-content">
                          <div class="block-actions" [class.keep]="blockGraph.hidden">
                            <div class="delete" (click)="deleteBlock(blockGraph)">
                              <fa-icon [icon]="faTrashAlt"></fa-icon>
                            </div>
                            <div class="show" (click)="blockGraph.hidden = !blockGraph.hidden"
                                 *ngIf="!blockGraph.hidden">
                              <fa-icon [icon]="faEye"></fa-icon>
                            </div>
                            <div class="show" (click)="blockGraph.hidden = !blockGraph.hidden"
                                 *ngIf="blockGraph.hidden">
                              <fa-icon [icon]="faEyeSlash"></fa-icon>
                            </div>
                            <div class="move" *ngIf="section.canOrderBlocks">
                              <fa-icon [icon]="faBars"></fa-icon>
                            </div>
                          </div>
                          <span (click)="displayBlockForm(blockGraph, block)">{{block.label}}</span>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

              </div>
              <div class="yan-btn add-block" *ngIf="canAddBlock"
                   [matMenuTriggerFor]="blockMenu">
                <fa-icon [icon]="faPlus"></fa-icon>
                <span>{{ 'MAIN_MENU.ADD_BLOCK' | translate }}</span>
              </div>
            </ng-container>

          </div>

        </ng-container>
      </ng-container>

      <div class="add-section" *ngIf="!displaySectionsPalette">
        <div (click)="showSectionPalette()" class="yan-btn secondary">
          <fa-icon [icon]="faPlus"></fa-icon>
          <span>{{ 'MAIN_MENU.ADD_SECTION_PAGE' | translate }}</span>
        </div>
      </div>

    </div>

    <div id="general-setting"
         class="animated tdFadeInUp"
         *ngIf="displaySetting">

      <div class="section-item">
        <div class="section-content current">
          <div class="section-main">
            <div class="section-ic">
              <img [src]="'/assets/img/sections/OTHER.svg'"/>
            </div>
            <div class="section-def">
              <div class="section-lbl">{{ 'MAIN_MENU.GENERAL_SETTING' | translate }}</div>
            </div>
          </div>
        </div>

        <div class="block-items">

          <div class="block-item"
               *ngFor="let graphBlock of template.settings">
            <ng-container *ngFor="let block of settingBlocks">
              <ng-container *ngIf="graphBlock.blockCode == block.code">
                <div class="brdr-l"></div>
                <div class="brdr-rd"></div>
                <div class="bi-content">
                  <span (click)="displayBlockForm(graphBlock, block)">{{block.label}}</span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="bm-footer">
    <div class="tab-item" [class.checked]="!displaySetting" (click)="displaySetting = false">
      <div class="tab-item-content">{{ 'MAIN_MENU.SECTIONS' | translate }}</div>
    </div>
    <div class="tab-item" [class.checked]="displaySetting" (click)="displaySetting = true">
      <div class="tab-item-content">{{ 'MAIN_MENU.GENERAL_SETTING' | translate }}</div>
    </div>
  </div>

  <!-- SECTION PALETTE -->
  <div *ngIf="displaySectionsPalette" class="form-modal-wrapper topper animated tdExpandIn">
    <div class="wrapper-content">
      <div class="wc-header">
        <div class="title">{{ 'MAIN_MENU.SECTIONS_PALETTE' | translate }}</div>
        <div (click)="closeSectionPalette()" class="close">
          <fa-icon [icon]="faTimes"></fa-icon>
        </div>
      </div>
      <div class="wc-body">
        <app-builder-sections-palette (sectionSelected)="addSection($event)"
                                      [sections]="paletteSections"></app-builder-sections-palette>
      </div>
    </div>
  </div>

  <!-- Fields form -->
  <div *ngIf="displayDynamicForm" class="form-modal-wrapper animated tdExpandIn">
    <div class="wrapper-content">
      <div class="wc-header">
        <div class="title">{{formLabel}}</div>
        <div (click)="closeDynamicForm()" class="close">
          <fa-icon [icon]="faTimes"></fa-icon>
        </div>
      </div>
      <div class="wc-body">
        <app-common-form [data]="formValues" [fields]="formFields"></app-common-form>
      </div>
    </div>
  </div>

</div>

<!-- BLOCK MENU -->
<mat-menu #blockMenu>
  <div class="yan-menu" id="block-menu"
       *ngIf="selectedSectionGraphCode && possibleBlocks && possibleBlocks.length > 0">
    <div class="mat-menu-item"
         *ngFor="let blockItem of possibleBlocks"
         (click)="addBlockToSection(blockItem)">
      {{blockItem.label}}
    </div>
  </div>
</mat-menu>
