import { Component, Input, OnInit } from '@angular/core';
import { AbstractFieldComponent } from "@app/component/abstrat-field.component";

@Component({
  selector: 'app-chip-field',
  templateUrl: './chip-field.component.html',
  styleUrls: ['./chip-field.component.scss']
})
export class ChipFieldComponent extends AbstractFieldComponent implements OnInit {

  @Input()
  multiple: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.multiple && !(this.data.value instanceof Array)) {
      this.data.value = [];
    }
  }

  toggleCheck(code: string): void {

    if (!this.multiple) {
      this.data.value = code;
    } else {
      if (this.data.value.indexOf(code) < 0) {
        this.data.value.push(code);
      } else {
        this.data.value.splice(this.data.value.indexOf(code), 1);
      }
    }

  }
}
