import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {EventManager} from "@app/service/event-manager.service";
import {EventManagerEnum} from "@app/model/enum/event-manager.enum";
import {YanLoaderService} from "@app/service/yan-loader.service";
import {BuilderService} from "@app/service/builder.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {Subscription} from "rxjs";
import {Location} from "@angular/common";
import {Template} from "@app/model/template.model";
import {GraphTemplate} from "@app/model/graph-template.model";
import {Section} from "@app/model/section.model";
import {Page} from "@app/model/page.model";

@Component({
  selector: 'app-builder-layout',
  templateUrl: './builder-layout.component.html',
  styleUrls: ['./builder-layout.component.scss']
})
export class BuilderLayoutComponent implements OnInit, OnDestroy {

  subscribers: Subscription = new Subscription();

  displayMode: number;

  // Template structure
  structure: Template;

  // Template graph
  templateData: GraphTemplate;

  storeDefaultDomain: string;

  selectedPageData: any | undefined;

  possibleSections: Section[];
  defaultSections: string[];

  currency: string;
  defaultLanguage: string;
  languages: string[];

  constructor(private eventManager: EventManager,
              private yanLoader: YanLoaderService,
              private router: Router,
              private location: Location,
              private route: ActivatedRoute,
              private builderService: BuilderService) {
  }

  ngOnInit(): void {

    this.yanLoader.startLoader('main-spinner');
    let url = `${environment.storeApiUrl}/graphs/data`;
    this.builderService.get(url).subscribe({
      next: (response: any) => {
        this.structure = response.template;
        this.templateData = response.graphTemplate;
        this.currency = response.currency;
        this.storeDefaultDomain = response.defaultDomain;
        this.defaultLanguage = response.defaultLanguage;
        this.languages = response.languages;

        // Current page
        this.selectedPageData = this.getFirstTimeSelectedPage();

        this.initPossibleSections();

        this.builderService.refreshPages(this.structure.pages, this.templateData.pages);
        this.yanLoader.stopLoader('main-spinner');
      },
      error: (e: any) => {
        this.router.navigateByUrl('/error/not-found').then();
      }
    });


    this.subscribers.add(this.eventManager.subscribe(EventManagerEnum.DISPLAY_MODE, (response: any) => {
      this.displayMode = response.displayMode;
    }));

  }

  /**
   *
   */
  getFirstTimeSelectedPage(): any {
    let page;
    const slug = this.route.snapshot.queryParamMap.get('page');
    if (slug) {
      page = this.templateData.landingPages?.find(landingPage => landingPage.slug === slug);
    }
    if (!page) {
      page = {...this.templateData.pages[0]}
    }
    return page;
  }

  /**
   * When the store is loaded
   * @param event
   */
  @HostListener('window:message', ['$event'])
  onPostMessage(event: any) {
    if (event.data === 'loaded') {
      this.eventManager.broadcast({name: EventManagerEnum.CHANGE_PAGE, page: this.getFirstTimeSelectedPage()});
    }
  }

  /**
   * Get possible sections
   */
  initPossibleSections(): void {
    if (!this.selectedPageData!.landingPageCode) {
      const templatePage = this.structure.pages.find((page: Page) => page.code === this.selectedPageData!.pageCode);
      if (templatePage && templatePage.possibleSections && templatePage.possibleSections.length > 0) {
        this.possibleSections = [...this.structure.sections.filter((item: Section) => templatePage.possibleSections!.indexOf(item.code) >= 0)];
        this.defaultSections = templatePage.defaultSections || [];
      } else {
        this.possibleSections = [...this.structure.sections.filter((item: Section) => item.uniquePerStore === false)];
        this.defaultSections = [];
      }
    } else {
      this.possibleSections = [...this.structure.sections.filter((item: Section) => this.structure.landingPagePossibleSections!.indexOf(item.code) >= 0)];
      this.defaultSections = this.structure.landingPageDefaultSections || [];
    }
  }

  /**
   * Change current page
   * @param page
   */
  changePage(page: any): void {
    this.yanLoader.startProgressBar();
    delete this.selectedPageData;
    setTimeout(() => {
      this.selectedPageData = {...page};
      this.initPossibleSections();
      this.eventManager.broadcast({name: EventManagerEnum.CHANGE_PAGE, page: {...page}});

      let url = '';
      if (!this.selectedPageData!.landingPageCode){
         url = this.router.createUrlTree([], {relativeTo: this.route}).toString();
      }else{
        url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {page: this.selectedPageData.slug}}).toString();
      }
      this.location.go(url)
      this.yanLoader.stopProgressBar();
    }, 5);
  }

  ngOnDestroy(): void {
    this.subscribers.unsubscribe();
  }
}

