<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
  <input [(ngModel)]="data.value"
         [placeholder]="field.placeholder ?  field.placeholder : ''" class="yan-input" type="text"/>
  <div class="yan-field-desc">{{field.designation}}</div>
</div>
