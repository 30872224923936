import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Section} from "@app/model/section.model";

@Component({
  selector: 'app-builder-sections-palette',
  templateUrl: './builder-sections-palette.component.html',
  styleUrls: ['./builder-sections-palette.component.scss']
})
export class BuilderSectionsPaletteComponent implements OnInit {

  @Input()
  sections: Section[];

  @Output()
  sectionSelected = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectSection(section: Section): void {
    this.sectionSelected.emit(section);
  }

}
