<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
  <mat-slider [(ngModel)]="data.value" [max]="field.max" [min]="field.min" [thumbLabel]="true"
              step="1"></mat-slider>
  <div class="yan-field-desc">{{field.designation}}</div>
</div>
