import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from "rxjs/operators";
import {Pageable} from "@app/model/pageable.model";
import {GraphPage} from "@app/model/graph-page.model";
import {Page} from "@app/model/page.model";
import {MediaType} from "@app/model/enum/media-type.enum";

@Injectable({providedIn: 'root'})
export class BuilderService {

  pages: Page[] = [];


  constructor(private http: HttpClient) {
  }


  refreshPages(structurePages: Page[], graphPages: GraphPage[]): void {

    const addedPages = graphPages.filter(graphPage => graphPage.addedPage !== undefined).map(graphPage => graphPage.addedPage!);
    if (addedPages && addedPages.length > 0) {
      this.pages = [...structurePages, ...addedPages];
    } else {
      this.pages = [...structurePages];
    }

    this.pages = this.pages.filter(page => page.slug !== 'collection' && page.slug !== 'thank-you' && page.slug !== 'cancel' && page.slug !== 'product');
  }

  /**
   * Filter collections
   * @param searchText
   */
  public filterCollections(pageable?: Pageable, searchText?: string): Observable<any> {

    const url = `${environment.productApiUrl}/collections`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (searchText) {
      params = params.append('searchText', searchText);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Filter customers to show in modals
   * @param filter
   * @param pageable
   */
  public filterProducts(pageable: Pageable, filter?: string): Observable<any> {
    let url = `${environment.productApiUrl}/products/shortList`;

    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('searchText', filter);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Get store menus
   */
  public getStoreMenus(): Observable<any> {
    let url = `${environment.storeApiUrl}/menus`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Get request
   * @param url
   */
  public get(url: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Update request
   * @param url
   * @param body
   */
  public update(url: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, body, httpOptions)
      .pipe(catchError(this.handleError));
  }


  uploadImage(file: File): Observable<Object> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    let url = `${environment.productApiUrl}/media/upload?type=` + MediaType.STORE;
    const httpOptions = {
      responseType: 'text' as 'json',
    };
    return this.http.post(url, formData, httpOptions);
  }

  /**
   * Handler request error
   * @param error
   */
  public handleError(error: any) {
    return throwError(error);
  }

}

