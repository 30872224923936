import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BuilderService } from "@app/service/builder.service";
import { Subscription } from "rxjs";
import { Pageable } from "@app/model/pageable.model";
import { AbstractFieldComponent } from "@app/component/abstrat-field.component";
import {BuilderFieldDatasourceType} from "@app/model/enum/builder-field-datasource-type.enum";

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html'
})
export class SelectFieldComponent extends AbstractFieldComponent implements OnInit, OnDestroy {

  @Input()
  multiple: boolean;

  subscription: Subscription = new Subscription();

  options: any[] = [];
  loader: boolean;

  constructor(private builderService: BuilderService) {
    super();
  }

  ngOnInit(): void {
    this.filterData();
  }

  filterData(filter?: string): void {

    if (this.field.datasource?.type === BuilderFieldDatasourceType.COLLECTION) {
      this.loader = true;
      const sub = this.builderService.filterCollections(new Pageable(), filter).subscribe(response => {
        this.options = response.data;
        this.loader = false;
      });
      this.subscription.add(sub);

    } else if (this.field.datasource?.type === BuilderFieldDatasourceType.PRODUCT) {
      this.loader = true;
      const sub = this.builderService.filterProducts(new Pageable(), filter).subscribe(response => {
        this.options = response;
        this.loader = false;
      });
      this.subscription.add(sub);
    } else if (this.field.datasource?.type === BuilderFieldDatasourceType.MENU && this.options.length === 0) {
      this.loader = true;
      const sub = this.builderService.getStoreMenus().subscribe(response => {
        this.options = response;
        this.loader = false;
      });
      this.subscription.add(sub);
    }

  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}


