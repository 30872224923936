<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
  <div class="radio-options">
    <mat-radio-group [(ngModel)]="data.value">
      <div class="radio-option" *ngFor="let option of field.datasource!.options">
        <mat-radio-button [value]="option.code">
          {{option.label}}
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <div class="yan-field-desc">{{field.designation}}</div>
  </div>
</div>
