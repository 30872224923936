export function genUniqueId(): string {
  const dateStr = Date
    .now()
    .toString(36); // convert num to base 36 and stringify

  const randomStr = Math
    .random()
    .toString(36)
    .substring(2, 8); // start at index 2 to skip decimal point

  return `${dateStr}-${randomStr}`;
}


export function getStoreId(): string {
  return window.location.pathname.split(';')[0]?.split('/')[1];
}


/**
 * Get error message from the back response
 * @param response
 * @param defaultMessage
 */
export function extractErrorMessagesFromResponse(response: any, defaultMessage?: string) {
  let messages = '';
  const errorMessages =  response.error?.errors;
  if (errorMessages && errorMessages.length > 0) {
    try {
      errorMessages.forEach((item: any) => {
        messages += '- ' + item + '<br/>';
      });
    } catch {
      messages += errorMessages;
    }
  } else if (defaultMessage) {
    messages = defaultMessage;
  }
  return messages;
}
