<div class="yan-field">
  <div class="yan-field-label">
    <label class="yan-label">
      <span>{{field.label}}</span>
    </label>
  </div>
  <div class="select-item">
    <ng-select
      [appendTo]="'body'"
      [items]="targetTypes"
      [(ngModel)]="data.value.target"
      (change)="changeType()">
      <ng-template ng-label-tmp let-item="item">
        {{ 'ENUMS.MENU_TARGET.'+item | translate }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{ 'ENUMS.MENU_TARGET.'+item | translate }}
      </ng-template>
    </ng-select>
  </div>
  <div
       *ngIf="data.value.target == menuTargetType.PAGE||data.value.target == menuTargetType.PRODUCT|| data.value.target == menuTargetType.COLLECTION">
    <ng-select
      [appendTo]="'body'"
      [items]="options"
      bindLabel="name"
      bindValue="id"
      [(ngModel)]="data.value.value"
      (search)="filterData($event.term)" [loading]="loader">
    </ng-select>
  </div>
  <input [(ngModel)]="data.value.value"
         *ngIf="data.value.target == menuTargetType.EXTERNAL"
         placeholder="Type your url .." class="yan-input" type="text"/>
  <div class="yan-field-desc">{{field.designation}}</div>
</div>
