import { Component, OnInit } from '@angular/core';
import { AbstractFieldComponent } from "@app/component/abstrat-field.component";

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
})
export class InputFieldComponent extends AbstractFieldComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
