  <div class="yan-modal" id="editor-form">
    <div (click)="closeModal()" class="close-modal"></div>
    <div class="yan-modal-title">{{ 'COMMON.EDITOR_FIELD.TEXT_EDITOR' | translate }}</div>
    <div class="yan-modal-content">
      <quill-editor theme="snow"
                    [(ngModel)]="data" (onEditorCreated)="onEditorCreated($event)"
                    placeholder="" [modules]="editorConfig"
      ></quill-editor>
    </div>
    <div class="yan-modal-btns">
      <button (click)="validate()" class="yan-btn">{{ 'BUTTON.SAVE' | translate }}</button>
      <button class="yan-btn white-btn" (click)="closeModal()">{{ 'BUTTON.CANCEL' | translate }}</button>
    </div>
  </div>
