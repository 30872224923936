import {Component, OnInit} from '@angular/core';
import {YanLoaderService} from "@app/service/yan-loader.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  direction: any;

  constructor(private translate: TranslateService,
              private yanLoaderService: YanLoaderService) {
  }

  ngOnInit(): void {
    this.direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    this.yanLoaderService.stopProgressBar();
  }
}
